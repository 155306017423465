<template>
  <li
    :class="{
      'one-device--with-claim': isAnyClaim,
      'one-device--unverified': unverifiedDevice,
    }"
    class="one-device"
  >
    <div class="one-device__verification-status-mobile-cont">
      <DeviceVerificationStatus
        :status="verificationStatus"
        :id="device.inventoryEquipmentId"
        :productLine="device.productLine"
        @deviceRemoved="handleDeviceRemoval"
      />
    </div>
    <div class="one-device__content-wrapper">
      <DeviceIcon
        :icon="device.productLineName"
        :gray-border="unverifiedDevice"
        class="one-device__device-icon"
      />
      <div class="one-device__texts">
        <p class="one-device__nickname">
          <template v-if="device.equipmentName">
            {{ device.equipmentName }}
          </template>
          <router-link
            v-else
            :to="{
              name: 'DeviceInfo',
              params: {id: device.inventoryEquipmentId},
              query: $route.query,
            }"
            class="hs-link one-device__add-nickname-btn"
          >
            + Add Nickname
          </router-link>
        </p>
        <div class="one-device__model-wrapper">
          <p class="one-device__model">{{ device.modelName }}</p>
          <router-link
            v-if="!deviceDeclined"
            :to="{
              name: 'DeviceInfo',
              params: {id: device.inventoryEquipmentId},
              query: $route.query,
            }"
            class="hs-link one-device__device-info-btn-mobile"
          >
            Device Info
          </router-link>
        </div>
      </div>
      <div class="one-device__verification-status-cont">
        <DeviceVerificationStatus
          :status="verificationStatus"
          :id="device.inventoryEquipmentId"
          :productLine="device.productLine"
          @deviceRemoved="handleDeviceRemoval"
        />
      </div>
      <div v-if="!deviceDeclined" class="one-device__device-info-btn-cont">
        <router-link
          :to="{
            name: 'DeviceInfo',
            params: {id: device.inventoryEquipmentId},
            query: $route.query,
          }"
          class="hs-link one-device__device-info-btn"
        >
          Device Info
        </router-link>
      </div>
      <GetServiceBtn
        v-if="!deviceDeclined"
        :id="device.inventoryEquipmentId"
        :product-line="device.productLine"
        :disabled="disableClaimBtn"
        :claims="deviceClaims"
        size="md"
        class="one-device__service-btn one-device__service-btn--desktop"
      />
    </div>
    <div
      v-if="isAnyClaim"
      class="one-device__claims-wrapper"
      :class="{
        'one-device__claims-wrapper--empty': isEmptyWrapper,
      }"
    >
      <DeviceClaim
        v-for="claim in onlyInProgressClaims"
        :key="claim.id"
        :claim="claim"
        class="one-device__claim"
      />
    </div>
    <div v-if="!deviceDeclined" class="one-device__service-btn-mob-wrapper">
      <GetServiceBtn
        :id="device.inventoryEquipmentId"
        :disabled="disableClaimBtn"
        :claims="deviceClaims"
        size="md"
        class="one-device__service-btn"
      />
    </div>
  </li>
</template>

<script>
import DeviceClaim from '@/components/device/list/MyDeviceListClaim';
import GetServiceBtn from '@/components/shared/GetServiceBtn';
import DeviceIcon from '@/components/shared/DeviceIcon';
import DeviceVerificationStatus from '@/components/device/DeviceVerificationStatus';
import {allowClaims} from '@/constants/env';
import {isInProgressStatus, isNotEligibleForRepairStatus} from '@/constants/esb_claim_statuses';
import {smartphone} from '@/constants/device_product_lines';

export default {
  components: {
    DeviceClaim,
    GetServiceBtn,
    DeviceIcon,
    DeviceVerificationStatus,
  },
  data() {
    return {
      allowClaims,
    };
  },
  props: {
    device: Object,
    onlineClaimEligible: Boolean,
    smartphoneSupport: Boolean,
  },
  computed: {
    isAnyClaim() {
      return this.deviceClaims?.length;
    },
    isAnyClaimInProgress() {
      return this.deviceClaims?.some(({serviceJobStatus}) => isInProgressStatus(serviceJobStatus));
    },
    // TODO: This is a workaround for making Request service button disabled.
    isAnyClaimWithNotEligible() {
      return this.deviceClaims?.some(({serviceJobSubStatusName}) =>
        isNotEligibleForRepairStatus(serviceJobSubStatusName)
      );
    },
    isEmptyWrapper() {
      return !this.isAnyClaimInProgress && !this.isAnyClaimWithNotEligible;
    },
    deviceClaims() {
      return this.device?.claims;
    },
    onlyInProgressClaims() {
      return this?.deviceClaims?.filter(({serviceJobStatus, serviceJobSubStatusName}) => {
        return (
          isInProgressStatus(serviceJobStatus) ||
          isNotEligibleForRepairStatus(serviceJobSubStatusName)
        );
      });
    },
    disableClaimBtn() {
      if (!allowClaims) {
        return true;
      }
      return (
        this.onlineClaimEligible === false ||
        this.isAnyClaimInProgress ||
        this.isAnyClaimWithNotEligible ||
        !this.supportSmartphoneClaim ||
        this.unverifiedDevice
      );
      // TODO: Rething disable "claim" button logic.
      // return !!(
      //   this.isAnyClaimInProgress ||
      //   !this.device.serialNumber
      // );
    },
    verificationStatus() {
      return this.device.equipmentStatus;
    },
    unverifiedDevice() {
      return this.verificationStatus && this.verificationStatus !== 'Active';
    },
    deviceDeclined() {
      return this.verificationStatus && this.verificationStatus === 'Rejected';
    },
    supportSmartphoneClaim() {
      return this.device.productLine === smartphone ? this.smartphoneSupport !== false : true;
    },
  },
  methods: {
    handleDeviceRemoval(id) {
      this.$store.commint('myDevices/REMOVE_DEVICE', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.one-device {
  display: block;
  width: 100%;
  padding: 16px;
  background-color: #fdfdfc;
  border: 1px solid #dfdfdf;
  border-radius: 4px;

  @include mf($gridMDBreakpoint) {
    padding: 12px 18px 12px 24px;
  }
  &--with-claim {
    @include mf($gridMDBreakpoint) {
      flex-wrap: wrap;
      padding-bottom: 0;
    }
  }
  &--unverified {
    border-style: dashed;
  }
  &__content-wrapper {
    display: flex;
    align-items: center;
  }
  &__device-icon {
    width: 54px;
    height: 54px;

    @include mf($gridMDBreakpoint) {
      width: 60px;
      height: 60px;
    }
  }
  &__texts {
    flex-grow: 1;
    max-width: calc(100% - 54px);
    padding-left: 18px;

    @include mf($gridMDBreakpoint) {
      flex-grow: 0;
      max-width: initial;
      white-space: nowrap;
    }
  }
  &__nickname {
    margin-bottom: 9px;
    font-weight: 700;
    font-size: 18px;

    @include mf($gridMDBreakpoint) {
      margin-bottom: 4px;
      font-size: 20px;
    }
  }
  &__model-wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__model {
    flex-shrink: 1;
    overflow: hidden;
    color: $mediumGray;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include mf($gridMDBreakpoint) {
      font-size: 18px;
    }
  }
  &__device-info-btn-cont {
    display: none;
    align-self: flex-end;
    margin-right: 0;
    white-space: nowrap;

    @include mf($gridMDBreakpoint) {
      display: block;
      align-self: center;
      margin-right: 12px;
    }
  }
  &__device-info-btn,
  &__device-info-btn-mobile {
    position: relative;
    flex-shrink: 0;
    padding-left: 30px;

    @include mf($gridMDBreakpoint) {
      align-self: center;
    }
    &::before {
      position: absolute;
      top: -4px;
      bottom: 0;
      left: 0;
      display: block;
      width: 30px;
      background-image: url('~@/assets/images/device-info-icon.svg');
      background-repeat: no-repeat;
      background-position: 0 50%;
      background-size: 22px 18px;
      content: '';
    }
  }
  &__device-info-btn-mobile {
    display: inline-block;

    @include mf($gridMDBreakpoint) {
      display: none;
    }
  }
  &__add-nickname-btn {
    font-size: 16px;
  }
  &__service-btn {
    @include mf($gridMDBreakpoint) {
      margin-left: auto;
    }
    &--desktop {
      display: none;

      @include mf($gridMDBreakpoint) {
        display: block;
      }
    }
  }
  &__remove-btn {
    margin-right: 15px;
  }
  &__claims-wrapper {
    display: block;
    width: 100%;
    margin-top: 12px;
    border-top: 1px solid #dfdfdf;
    &--empty {
      border: none;
    }
  }
  &__service-btn-mob-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    padding-top: 13px;
    border-top: 1px solid $gray;

    @include mf($gridMDBreakpoint) {
      display: none;
    }
  }
  &--with-claim &__service-btn-mob-wrapper {
    margin-top: 0;
  }
  &__verification-status-mobile-cont {
    display: block;
    width: calc(100% + 32px);
    margin: -16px -16px 14px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    @include mf($gridMDBreakpoint) {
      display: none;
    }
  }
  &__verification-status-cont {
    display: none;

    @include mf($gridMDBreakpoint) {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      margin-right: 25px;
    }
  }
}
</style>
