<template>
  <ul class="my-devices-list">
    <OneDevice
      v-for="device in devices"
      :key="device.inventoryEquipmentId"
      :device="device"
      :online-claim-eligible="onlineClaimEligible"
      :smartphone-support="smartphoneSupport"
      class="my-devices-list__item"
    />
  </ul>
</template>

<script>
import OneDevice from '@/components/device/list/MyDevicesListItem';

export default {
  components: {
    OneDevice,
  },
  props: {
    devices: Array,
    onlineClaimEligible: Boolean,
    smartphoneSupport: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.my-devices-list {
  display: block;
  width: 100%;
  margin-top: 14px;
  &__item:not(:last-of-type) {
    margin-bottom: 14px;
  }
}
</style>
