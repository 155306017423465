<template>
  <hs-main class="my-devices">
    <template v-if="loadingInProgress">
      <hs-heading title="My Devices" />
      <div class="my-devices__loader-cont">
        <i class="my-devices__loader"></i>
      </div>
    </template>
    <template v-else>
      <div class="my-devices__heading-wrapper">
        <hs-heading :description="titleDescription" title="My Devices" class="my-devices__heading">
          <template #title-elements>
            <hs-button
              v-if="isThereAnyDevice"
              :link="{
                name: 'AddDevice',
                query: $route.query,
              }"
              route
              variant="secondary"
              size="sm"
              class="my-devices__add-device-btn-mob"
            >
              <span>+</span>Add Device
            </hs-button>
          </template>
        </hs-heading>
        <template v-if="isThereAnyDevice">
          <hs-search
            v-model="searchFilter"
            placeholder="Search for brand, model or device nickname"
            class="my-devices__filter-input"
          />
          <hs-button
            :link="{
              name: 'AddDevice',
              query: $route.query,
            }"
            route
            variant="secondary"
            size="sm"
            class="my-devices__add-device-btn"
          >
            <span>+</span>Add Device
          </hs-button>
        </template>
      </div>
      <ThirtyDaysNote v-if="thirtyDaysExpired === false" class="my-devices__thirty-days-note" />
      <DevicesList
        v-if="showDevicesList"
        :devices="filteredDevices"
        :online-claim-eligible="onlineClaimEligible"
        :smartphone-support="supportSmartphoneClaim"
        class="my-devices__devices-list"
      />
      <NoSearchResults v-else-if="showNoSearchResults" class="my-devices__no-filtered-devices" />
      <NoDevices v-else class="my-devices__no-devices" />
      <div v-if="isThereAnyClaim" class="my-devices__history-link-wrapper">
        <router-link :to="{name: 'ServiceHistory', query: $route.query}" class="hs-link"
          >View Service History</router-link
        >
      </div>
    </template>
  </hs-main>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import NoDevices from '@/components/device/list/NoContent';
import NoSearchResults from '@/components/device/list/NoSearchResults';
import ThirtyDaysNote from '@/components/device/ThirtyDaysNote';
import DevicesList from '@/components/device/list/MyDevicesList';
import Vue from 'vue';

export default {
  metaInfo: {
    titleTemplate: 'My Devices - %s',
  },
  name: 'MyDevicesList',
  components: {
    NoSearchResults,
    NoDevices,
    ThirtyDaysNote,
    DevicesList,
  },
  data() {
    return {
      searchFilter: '',
      loadingInProgress: true,
    };
  },
  computed: {
    ...mapState('myDevices', ['error']),
    ...mapState('jobInfo', ['claimsList']),
    ...mapState('user', [
      'onlineClaimEligible',
      'thirtyDaysExpired',
      'supportSmartphoneClaim',
      'coverageStartDate',
      'coverageEndDate',
    ]),
    ...mapGetters('myDevices', ['devicesWithClaims', 'activeDevices']),
    filteredDevices() {
      // TODO: review checks and conditions when BE and FE will be connected
      // Think about moving this logic to the store somehow
      if (!this.searchFilter) return this.devicesWithClaims;
      let res = JSON.parse(JSON.stringify(this.devicesWithClaims));
      if (res && Array.isArray(res)) {
        const searchString = this.searchFilter.toLowerCase().trim();
        res = res.filter((i) => {
          const make = i.make?.toLowerCase().trim() || '';
          const model = i.modelName?.toLowerCase().trim() || '';
          const equipmentName = i.equipmentName?.toLowerCase().trim() || '';
          return (
            make.indexOf(searchString) !== -1 ||
            model.indexOf(searchString) !== -1 ||
            equipmentName.indexOf(searchString) !== -1
          );
        });
      }
      return res || [];
    },
    showDevicesList() {
      return this.isThereAnyDevice && !this.showNoSearchResults;
    },
    showNoSearchResults() {
      return this.isThereAnyDevice && !this.filteredDevices.length;
    },
    isThereAnyDevice() {
      return this.activeDevices?.length;
    },
    isThereAnyClaim() {
      return this.claimsList?.length;
    },
    titleDescription() {
      return this.isThereAnyDevice
        ? 'Add each of your devices. Then request service for the ones you need help with.'
        : '';
    },
  },
  beforeCreate() {
    const {customerRef} = this.$route.query;
    this.$store
      .dispatch('user/DECRYPT_PARAMS', {
        customerRef,
      })
      .then((resp) => {
        Promise.all([
          this.$store.dispatch('myDevices/GET_MY_DEVICES', {
            customerRef: resp?.customerRef,
          }),
          this.$store.dispatch('jobInfo/GET_CLAIMS_LIST', {
            customerRef: resp?.customerRef,
          }),
        ]).finally(() => {
          this.loadingInProgress = false;
        });
      });
  },
  beforeDestroy() {
    this.$store.commit('myDevices/CLEAR_STORE');
  },
};
</script>

<style lang="scss" scoped>
.my-devices {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 15px 0;

  @include mf($gridMDBreakpoint) {
    padding: 60px 0;
  }
  &__loader-cont {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }
  &__loader {
    display: block;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    background-image: url('~@/assets/images/hs-loader.gif');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% auto;
  }
  &__heading-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include mf($gridMDBreakpoint) {
      flex-wrap: nowrap;
    }
  }
  &__heading {
    flex-grow: 1;
    max-width: auto;

    @include mf($gridMDBreakpoint) {
      flex-grow: 0;
      max-width: 364px;
    }
  }
  &__filter-input {
    display: block;
    order: 3;
    width: 100%;
    margin-top: 14px;

    @include mf($gridMDBreakpoint) {
      order: 2;
      width: 394px;
      margin-top: 0;
      margin-right: 17px;
      margin-left: auto;
    }
  }
  &__add-device-btn {
    display: none;
    flex-shrink: 0;
    order: 2;
    margin-right: 5px;
    font-size: 22px;

    @include mf($gridMDBreakpoint) {
      display: block;
    }
    span {
      margin-right: 5px;
    }
  }
  &__add-device-btn-mob {
    @include mf($gridMDBreakpoint) {
      display: none;
    }
  }
  &__thirty-days-note {
    margin-top: 11px;
  }
  &__devices-list {
    margin-top: 13px;
  }
  &__no-devices {
    margin-top: 18px;
  }
  &__history-link-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 34px;
  }
}
</style>
