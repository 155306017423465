<template>
  <div class="no-devices">
    <div class="no-devices__image"></div>
    <div class="no-devices__text">
      <h2 class="no-devices__note-title">Add your devices before requesting service.</h2>
      <p>
        You can add devices that you own and are primarily located and used in and around your home.
        Service can be requested 30 days after the start date of your plan.
      </p>
    </div>
    <hs-button
      class="no-devices__btn"
      size="md"
      route
      :link="{name: 'AddDevice', query: $route.query}"
    >
      <span>+</span>Add Device
    </hs-button>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.no-devices {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border: 1px dashed $lightGray;
  border-radius: 4px;
  &__image {
    display: block;
    width: 100%;
    max-width: 252px;
    height: 173px;
    background-image: url('~@/assets/images/no-devices.svg');
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;

    @include mf($gridMDBreakpoint) {
      max-width: 312px;
      height: 214px;
    }
  }
  &__text {
    display: block;
    width: 100%;
    max-width: 521px;
    margin: 35px 0 16px;
    font-size: 15px;
    text-align: center;

    @include mf($gridMDBreakpoint) {
      font-size: 18px;
    }
  }
  &__note-title {
    margin: 0;
    font-weight: 700;
    font-size: 18px;

    @include mf($gridMDBreakpoint) {
      font-size: 20px;
    }
  }
  &__btn {
    font-weight: 700;
    span {
      margin-right: 5px;
      font-weight: 900;
      font-size: 20px;
    }
  }
}
</style>
