var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"one-device",class:{
    'one-device--with-claim': _vm.isAnyClaim,
    'one-device--unverified': _vm.unverifiedDevice,
  }},[_c('div',{staticClass:"one-device__verification-status-mobile-cont"},[_c('DeviceVerificationStatus',{attrs:{"status":_vm.verificationStatus,"id":_vm.device.inventoryEquipmentId,"productLine":_vm.device.productLine},on:{"deviceRemoved":_vm.handleDeviceRemoval}})],1),_c('div',{staticClass:"one-device__content-wrapper"},[_c('DeviceIcon',{staticClass:"one-device__device-icon",attrs:{"icon":_vm.device.productLineName,"gray-border":_vm.unverifiedDevice}}),_c('div',{staticClass:"one-device__texts"},[_c('p',{staticClass:"one-device__nickname"},[(_vm.device.equipmentName)?[_vm._v(" "+_vm._s(_vm.device.equipmentName)+" ")]:_c('router-link',{staticClass:"hs-link one-device__add-nickname-btn",attrs:{"to":{
            name: 'DeviceInfo',
            params: {id: _vm.device.inventoryEquipmentId},
            query: _vm.$route.query,
          }}},[_vm._v(" + Add Nickname ")])],2),_c('div',{staticClass:"one-device__model-wrapper"},[_c('p',{staticClass:"one-device__model"},[_vm._v(_vm._s(_vm.device.modelName))]),(!_vm.deviceDeclined)?_c('router-link',{staticClass:"hs-link one-device__device-info-btn-mobile",attrs:{"to":{
            name: 'DeviceInfo',
            params: {id: _vm.device.inventoryEquipmentId},
            query: _vm.$route.query,
          }}},[_vm._v(" Device Info ")]):_vm._e()],1)]),_c('div',{staticClass:"one-device__verification-status-cont"},[_c('DeviceVerificationStatus',{attrs:{"status":_vm.verificationStatus,"id":_vm.device.inventoryEquipmentId,"productLine":_vm.device.productLine},on:{"deviceRemoved":_vm.handleDeviceRemoval}})],1),(!_vm.deviceDeclined)?_c('div',{staticClass:"one-device__device-info-btn-cont"},[_c('router-link',{staticClass:"hs-link one-device__device-info-btn",attrs:{"to":{
          name: 'DeviceInfo',
          params: {id: _vm.device.inventoryEquipmentId},
          query: _vm.$route.query,
        }}},[_vm._v(" Device Info ")])],1):_vm._e(),(!_vm.deviceDeclined)?_c('GetServiceBtn',{staticClass:"one-device__service-btn one-device__service-btn--desktop",attrs:{"id":_vm.device.inventoryEquipmentId,"product-line":_vm.device.productLine,"disabled":_vm.disableClaimBtn,"claims":_vm.deviceClaims,"size":"md"}}):_vm._e()],1),(_vm.isAnyClaim)?_c('div',{staticClass:"one-device__claims-wrapper",class:{
      'one-device__claims-wrapper--empty': _vm.isEmptyWrapper,
    }},_vm._l((_vm.onlyInProgressClaims),function(claim){return _c('DeviceClaim',{key:claim.id,staticClass:"one-device__claim",attrs:{"claim":claim}})}),1):_vm._e(),(!_vm.deviceDeclined)?_c('div',{staticClass:"one-device__service-btn-mob-wrapper"},[_c('GetServiceBtn',{staticClass:"one-device__service-btn",attrs:{"id":_vm.device.inventoryEquipmentId,"disabled":_vm.disableClaimBtn,"claims":_vm.deviceClaims,"size":"md"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }