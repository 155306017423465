<template>
  <div class="thirty-days-note">
    <InfoIcon fill-color="#8D7146" />
    <div class="thirty-days-note__text-wrapper">
      <p class="thirty-days-note__title-text">We’re sorry. You can’t request service right now.</p>
      <p>
        You’re still within 30 days of the date you purchased your plan. After this waiting period,
        you’ll be able to request service.
      </p>
    </div>
  </div>
</template>

<script>
import InfoIcon from '@/components/shared/InfoIcon';

export default {
  components: {
    InfoIcon,
  },
};
</script>

<style lang="scss" scoped>
.thirty-days-note {
  display: block;
  padding: 12px 20px;
  color: #8d7146;
  font-weight: 500;
  font-size: 16px;
  background-color: #fcf8e3;
  border-radius: 3px;

  @include mf($gridMDBreakpoint) {
    display: flex;
    align-items: center;
    padding: 14px 16px;
  }
  &__text-wrapper {
    font-size: 16px;

    @include mf($gridMDBreakpoint) {
      margin-left: 17px;
    }
  }
  &__title-text {
    margin-left: 5px;
    font-weight: 700;

    @include mf($gridMDBreakpoint) {
      margin-left: 0;
      font-size: 18px;
    }
  }
  * {
    display: inline;

    @include mf($gridMDBreakpoint) {
      display: block;
    }
  }
  svg {
    width: 14px !important;
    height: 14px !important;

    @include mf($gridMDBreakpoint) {
      width: 18px !important;
      height: 18px !important;
    }
  }
}
</style>
